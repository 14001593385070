<template>
    <div class="bkColor">
        <headTitle :title="title" :routerPath="routerPath"></headTitle>
        <van-search v-model="value" input-align="center" placeholder="请输入搜索关键词" shape="round" show-action @search="onSearch"
            @cancel="onCancel" style="margin-bottom: 2vh" />
        <div class="search">
            <div>年份:</div>
            <div style="
            display: flex;
            justify-content: space-around;
            align-items: center">
                <van-dropdown-menu>
                    <van-dropdown-item v-model="queryFrom.year" :options="yearOption" @change="changeYear" />
                </van-dropdown-menu>
                <div class="searchTitle">金额来源：</div>
                <van-dropdown-menu>
                    <van-dropdown-item v-model="queryFrom.inout" :options="inoutOption" @change="changePjState" />
                </van-dropdown-menu>
                <div class="searchTitle">归属：</div>
                <van-dropdown-menu>
                    <van-dropdown-item v-model="queryFrom.belong" :options="belongOption" @change="changeBelong" />
                </van-dropdown-menu>
            </div>
        </div>
        <div class="list">
            <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
                <van-list v-model="loading" :finished="finished" :error.sync="error" error-text="请求失败，点击重新加载"
                    finished-text="没有更多了" @load="getTransactionAmountList">
                    <div class="listCell" v-for="item in list" :key="item.id" @click="goDetails(item.id)"
                        @mouseup="up($event)" @mousedown="down($event)">
                        <van-swipe-cell>
                            <div class="cellName">
                                {{ item.description }}
                                <div class="status">
                                    <span :class="{
                                        blue: item.inout == 1,
                                        green: item.inout == 2,
                                    }">
                                        {{ stateText(item.inout) }}</span>
                                </div>
                            </div>
                            <div class="cellText">流水号：{{ item.transactionNo }}</div>
                            <div class="cellText">对方单位：{{ item.supplier }}</div>
                            <div class="cellText">往来金额金额：{{ item.price }}元</div>
                            <div class="cellText">往来金额日期：{{ item.transactionTime }}</div>
                            <template #right>
                                <van-button native-type="button" type="danger" @click="delCell(item.id, 2)">删除</van-button>
                            </template>
                        </van-swipe-cell>
                    </div>
                </van-list>
            </van-pull-refresh>
        </div>
        <div class="addBtn">
            <img src="../assets/icon/xz_icon.png" alt="" @click="isModify = true" />
        </div>
        <div class="exportBtn" @click="exportFile" v-show="powerList.includes('2') || powerList.includes('1')">导出</div>
        <div class="importBtn" @click="importFile" v-show="powerList.includes('2') || powerList.includes('1')">导入</div>
        <van-popup v-model="isModify" :style="{ width: '80%', padding: '20px' }" round>

            <van-form validate-first @failed="onFailed" @submit="submitForm" ref='form'>
                <van-field name="radio" label="归属单位（请以发票抬头一致）" required>
                    <template #input>
                        <van-radio-group v-model="addFrom.belong" direction="horizontal">
                            <van-radio :name="1">大美自然</van-radio>
                            <van-radio :name="2">中创科技</van-radio>
                        </van-radio-group>
                    </template>
                </van-field>
                <van-field name="radio" label="金额来源" required>
                    <template #input>
                        <van-radio-group v-model="addFrom.inout" direction="horizontal">
                            <van-radio :name="1">支出</van-radio>
                            <van-radio :name="2">收入</van-radio>
                        </van-radio-group>
                    </template>
                </van-field>
                <van-field v-model="addFrom.price" type="number" label="往来金额（元）" placeholder="请输入"
                    :rules="[{ required: true, message: '往来金额不能为空' }]" required />
                <van-field v-model="addFrom.supplier" label="对方单位" placeholder="请输入"
                    :rules="[{ required: true, message: '对方单位不能为空' }]" required />
                <van-field v-model="addFrom.transactionTime" is-link readonly label="收款时间" placeholder="请选择收款时间"
                    @click="showC = true" :rules="[{ required: true, message: '收款时间不能为空' }]" required />
                <van-popup v-model="showC" round position="bottom">
                    <van-datetime-picker v-model="currentDate" type="date" title="收款时间" @confirm="chooseTime"
                        @cancel="showC = false" />
                </van-popup>
                <van-field v-model="addFrom.description" rows="2" autosize label="金额说明" type="textarea" maxlength="800"
                    placeholder="请输入金额说明" show-word-limit :rules="[{ required: true, message: '金额说明不能为空' }]" required />
                <van-button round block type="info" native-type="submit" size="small" :disabled="isLoading"
                    :loading="isLoading" loading-text="加载中...">提交</van-button>
            </van-form>
        </van-popup>
    </div>
</template>
    
<script>
import headTitle from "../components/headTitle.vue";
import Vue from "vue";
import {
    Search,
    Toast,
    List,
    PullRefresh,
    Cell,
    SwipeCell,
    Button,
    Dialog,
    Radio,
    RadioGroup,
    DatetimePicker,
    Form,
    Popup,
    Field
} from "vant";

import { settlementText, timeChange, yearOption } from "../units/units";
import { transactionList, transactionExport, transactionDeltel, transactionAdd } from "../api/transactionAmount";

import { getToken } from "../units/auth";

Vue.use(Search);
Vue.use(Toast);
Vue.use(List);
Vue.use(PullRefresh);
Vue.use(Cell);
Vue.use(SwipeCell);
Vue.use(Button);
Vue.use(Dialog);
Vue.use(Radio)
Vue.use(RadioGroup)
Vue.use(DatetimePicker);
Vue.use(Form)
Vue.use(Popup)
Vue.use(Field)
export default {
    name: "transactionAmount",
    components: {
        headTitle,
    },
    data() {
        return {
            title: "项目往来金额列表",
            yearOption: yearOption,
            inoutOption: [
                { text: "全部", value: null },
                { text: "收入", value: 1 },
                { text: "支出", value: 2 },
            ],
            belongOption: [
                { text: "全部", value: null },
                { text: "大美", value: "1" },
                { text: "中创", value: "2" },
            ],
            value: "",
            list: [],
            loading: false,
            finished: false,
            refreshing: false,
            error: false,
            pageX: 0,
            pageY: 0,
            isDrag: false,
            queryFrom: {
                year: null,
                inout: null,
                vague: "",
                belong: null,
                pageSize: 10,
                pageNum: 1,
            },
            addFrom: {
                belong: 1,
                inout: 1,
                price: null,
                transactionTime: '',
                description: '',
                supplier: "",
            },
            powerList: [], //value 1总经理 2财务 3行政
            userId: "",
            routerPath: '/',
            belong: '',
            showC: false,
            currentDate: new Date(),
            isModify: false,
            isLoading: false,
            equipment: false
        };
    },
    mounted() {
        this.getUserId();
        this.getPowerList();
        if (this.$route.query.type) {
            this.changePjState(this.$route.query.type * 1)
        }
        this.queryFrom.year = this.$route.query.year * 1 || null
        this.queryFrom.belong = this.$route.query.belong > 0 ? this.$route.query.belong : null
        this.routerPath = this.$route.query.routerPath ? this.$route.query.routerPath : "/";
        this.isMobile()
    },
    methods: {
        //获取权限渲染页面
        getPowerList() {
            this.powerList = JSON.parse(getToken("powerList") || "[]");
        },
        getUserId() {
            this.userId = getToken("userId");
        },
        getTransactionAmountList() {
            this.loading = true;
            transactionList(this.queryFrom).then((res) => {
                this.list =
                    this.queryFrom.pageNum == 1
                        ? res.data.rows
                        : this.list.concat(res.data.rows);
                this.refreshing = false;
                this.loading = false;
                this.queryFrom.pageNum = this.queryFrom.pageNum + 1;
                if (
                    res.data.total <=
                    (this.queryFrom.pageNum - 1) * this.queryFrom.pageSize
                ) {
                    this.finished = true;
                }
            });
        },
        //财务是否结算
        settlement(f) {
            return settlementText(f);
        },
        stateText(t) {
            switch (t) {
                case 1:
                    return '收入'
                case 2:
                    return '支出'
                default:
                    return '--'
            }
        },
        changeYear(val) {
            this.queryFrom.year = val;
            this.onRefresh();
        },
        changePjState(val) {
            this.queryFrom.inout = val;
            this.onRefresh();
        },
        changeBelong(val) {
            this.queryFrom.belong = val;
            this.onRefresh();
        },
        onSearch(val) {
            console.log(val);
            this.queryFrom.vague = val;
            this.onRefresh();
        },
        //搜索框取消
        onCancel() {
            this.queryFrom.vague = "";
            this.queryFrom.state = null;
            this.queryFrom.isSettle = null;
            this.queryFrom.belong = null;
            this.onRefresh();
        },
        //下拉刷新
        onRefresh() {
            // 清空列表数据
            this.finished = false;
            this.queryFrom.pageNum = 1;
            this.list = [];
            // 重新加载数据
            // 将 loading 设置为 true，表示处于加载状态
            this.getTransactionAmountList();
        },
        chooseTime() {
            this.showC = false
            this.addFrom.transactionTime = timeChange(this.currentDate, true)
        },
        //校验不通过
        onFailed(errorInfo) {
            Toast.fail(errorInfo.errors[0].message)
        },
        submitForm() {
            this.isLoading = true
            transactionAdd(this.addFrom).then(res => {
                if (res.data.code == 200) {
                    this.isLoading = false
                    Toast.success("提交成功");
                    this.isModify = false
                    this.onRefresh();
                    this.addFrom = {
                        belong: 1,
                        inout: 1,
                        price: null,
                        transactionTime: '',
                        description: '',
                        supplier: "",
                    }
                }
            }).catch(() => {
                this.isLoading = false
            })
        },
        exportFile() {
            if (!this.equipment) {
                Toast('此功能目前只能电脑端使用！')
                return
            }
            transactionExport(this.queryFrom).then((res) => {
                var blob = new Blob([res.data], {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                });
                if ("download" in document.createElement("a")) {
                    // 非IE下载
                    var elink = document.createElement("a");
                    elink.download = "往来金额列表";
                    elink.style.display = "none";
                    elink.href = URL.createObjectURL(blob);
                    document.body.appendChild(elink);
                    elink.click();
                    URL.revokeObjectURL(elink.href); // 释放URL 对象
                    document.body.removeChild(elink);
                } else {
                    // IE10+下载
                    navigator.msSaveBlob(blob, "往来金额列表");
                }
            });
        },
        importFile() {
            if (!this.equipment) {
                Toast('此功能目前只能电脑端使用！')
                return
            }
            this.$router.push({
                path: "/fileImport",
                query: { title: "往来金额", routerPath: "/transactionAmount" },
            });
        },
        goDetails(id) {
            if (this.isDrag) {
                return;
            }
            this.$router.push({
                path: "/transactionAmountDetails",
                query: { id: id },
            });
        },
        delCell(id) {
            Dialog.confirm({
                message: `确定删除？`,
            }).then(() => {
                transactionDeltel(id).then(res => {
                    if (res.data.code == 200) {
                        this.onRefresh()
                    }
                })
            });
        },
        up(event) {
            if (this.pageX !== event.pageX) {
                this.isDrag = true;
            } else {
                this.isDrag = false;
            }
        },
        down(event) {
            this.pageX = event.pageX;
            this.pageY = event.pageY;
        },
        isMobile() {
            this.equipment = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i) ? false : true
        }
    },
};
</script>
    
<style lang="less" scoped>
.blue {
    color: #1989fa;
}

.red {
    color: #e86f61;
}

.green {
    color: #00ab45;
}

.bkColor {
    width: 100vw;
    min-height: 100vh;
    background: #f6f6f6;
    position: relative;
}

.search {
    width: 100vw;
    height: 8vh;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2vh;
    background: #fff;
    font-size: 13px;
    line-height: 8vh;
}

.searchTitle {
    margin-left: 2vw;
}

/deep/ .van-dropdown-menu__title {
    padding: 0 8px 0 0;
}

/deep/ .van-ellipsis {
    font-size: 13px;
}

.list {
    margin-top: 2vh;
}

.listCell {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 0 5vw 1vh;
    background: #fff;
    text-align: left;
}

.cellName {
    width: 90%;
    font-size: 1rem;
    font-weight: 600;
    border-top: 2px solid #f6f6f6;
    padding-top: 1vh;
    padding-bottom: 1vh;

    .status {
        float: right;
        font-size: 0.8rem;
        font-weight: 500;
        text-align: right;
    }
}

/deep/ .van-list :first-of-type.listCell .cellName {
    border-top: unset;
}

.cellText {
    width: 70%;
    color: #797979;
    margin-bottom: 0.5vh;
    font-size: 0.7rem;
    word-break: break-all;
    line-height: 1;
}

.addBtn {
    width: 55px;
    height: 55px;
    position: fixed;
    top: 77%;
    left: 77%;
    cursor: pointer;

    img {
        width: 55px;
        height: 55px;
    }
}

.exportBtn {
    width: 50px;
    height: 50px;
    position: fixed;
    top: 55%;
    left: 77.1%;
    background: #3495fc;
    border-radius: 50%;
    line-height: 50px;
    color: #fff;
    box-shadow: 0px 6px 8px 0px rgba(122, 117, 117, 0.39);
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.importBtn {
    width: 50px;
    height: 50px;
    position: fixed;
    top: 66%;
    left: 77.1%;
    background: #3495fc;
    border-radius: 50%;
    line-height: 50px;
    color: #fff;
    box-shadow: 0px 6px 8px 0px rgba(122, 117, 117, 0.39);
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/deep/ .van-button {
    font-size: 16px;
}

/deep/ .van-cell {
    flex-wrap: wrap;
    margin-bottom: 1vh;
}

/deep/ .van-cell__title {
    color: #797979;
}

/deep/ .van-field__label,
.van-cell__title {
    width: 100%;
}
</style>